import React, { ChangeEvent, useEffect, useState } from "react";
import { Paper, TextareaAutosize } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { getNewId, useI18n } from "compass-commons";
import PropTypes from "prop-types";
import { Chip } from "dms-lib";
import { ReportEmail } from "../../models/ReportEmail";
import StateService from "../../services/StateService";
import { useStateContext } from "../../contexts/StateContext";
import { isValidEmail } from "../../utils/Util";
import "./emailForm.module.css";

interface EmailFormProps {
  shouldSendEmail: boolean;
  sendEmailCallback: any;
  emailSentCallback?: any;
  isValidToSend?: any;
}

interface ChipData {
  key: number;
  label: string;
}

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const EmailForm = (props: EmailFormProps): JSX.Element => {
  const {
    shouldSendEmail,
    sendEmailCallback,
    emailSentCallback,
    isValidToSend,
  } = props;
  const stateService: StateService = useStateContext();
  const { currentReportCart } = stateService;
  const [emailBody, setEmailBody] = useState("");
  const [inputValue, setInputValue] = React.useState("");
  const { t: translate } = useI18n();
  const [emailAddresses, setEmailAddresses] = React.useState<
    readonly ChipData[]
  >([]);

  const handleDelete = (chipToDelete: ChipData) => () => {
    setEmailAddresses((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  useEffect(() => {
    if (shouldSendEmail) {
      const emailList: string[] =
        emailAddresses?.length > 0
          ? emailAddresses.map((chip) => {
              return chip.label;
            })
          : [inputValue];
      const emailDTO: ReportEmail = {
        reportCartId: currentReportCart?.value?.id || "1",
        emailHeader: "DMS Report PDF",
        emailBody,
        toEmails: emailList,
        reportName: "report.pdf",
      };
      sendEmailCallback(emailDTO);
      emailSentCallback();
      setEmailBody("");
    }
  }, [shouldSendEmail, emailSentCallback, sendEmailCallback]);

  useEffect(() => {
    if (
      emailAddresses.length > 0 ||
      (inputValue.length > 0 && isValidEmail(inputValue))
    ) {
      isValidToSend(true);
    } else {
      isValidToSend(false);
    }
  }, [emailAddresses, inputValue]);

  const isSafeString = (str: string) => {
    const regEx = /^[^<%$>{}]+$/u;
    return str && str.match(regEx);
  };

  const updateEmailBody = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const textInput = e?.target?.value as string;
    if (textInput === "" || isSafeString(textInput)) {
      setEmailBody(textInput);
    }
  };

  function handleKeyDown(event) {
    if (event.key === "Enter" || event.key === " ") {
      const emailInput = event.target.value.trim();

      if (!isValidEmail(emailInput)) {
        return;
      }

      const duplicatedEmails = emailAddresses.filter(
        (o) => o.label.indexOf(emailInput) !== -1
      );

      if (duplicatedEmails.length > 0) {
        setInputValue("");
        return;
      }
      if (!emailInput.replace(/\s/g, "").length) return;

      const updatedEmailList: ChipData[] = [...emailAddresses];
      const chipData: ChipData = {
        key: parseInt(getNewId(), 16),
        label: emailInput,
      };
      updatedEmailList.push(chipData);
      setEmailAddresses(updatedEmailList);
      setInputValue("");
    }
    if (
      emailAddresses.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      setEmailAddresses(emailAddresses.slice(0, emailAddresses.length - 1));
    }
  }

  function handleInputChange(event) {
    setInputValue(event?.target?.value);
  }

  return (
    <DialogContent style={{ height: "30vh", overflowX: "hidden" }}>
      <div className="row">
        <div className="col">
          <p>
            <b>{translate("emailForm.addEmailAddress")}</b>
          </p>
        </div>
      </div>
      <div style={{ paddingBottom: "var(--msi-ui-spacing-xl)" }}>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            listStyle: "none",
            maxHeight: "150px",
            overflowY: "scroll",
            p: 0.5,
            m: 0,
          }}
          component="ul"
          className="email-input-block"
        >
          {emailAddresses.map((data) => {
            return (
              <ListItem key={data.key}>
                <Chip
                  color="default"
                  label={data.label}
                  onDelete={handleDelete(data)}
                />
              </ListItem>
            );
          })}
          <ListItem key={0} style={{ width: "100%" }}>
            <input
              id="outlined-basic"
              placeholder={translate("emailForm.addEmailPlaceholder")}
              onKeyDown={handleKeyDown}
              onChange={handleInputChange}
              value={inputValue}
              inputMode="email"
              className="email-input"
              data-cr="email-input"
            />
          </ListItem>
        </Paper>
      </div>
      <div className="row">
        <div className="col">
          <p>
            <b>{translate("emailForm.addMessage")}</b>
          </p>
        </div>
      </div>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
          listStyle: "none",
          maxHeight: "200px",
          overflowY: "scroll",
          width: "100%",
          p: 0.5,
          m: 0,
        }}
        component="ul"
        className="email-input-block"
      >
        <div style={{ width: "100%" }}>
          <TextareaAutosize
            aria-label="email body"
            minRows={5}
            placeholder={translate("emailForm.messageAreaPlaceholder")}
            className="email-body-area"
            onChange={(e) => updateEmailBody(e)}
            value={emailBody}
            inputMode="email"
            data-cr="email-body-text"
          />
        </div>
      </Paper>
    </DialogContent>
  );
};
EmailForm.defaultProps = {
  emailSentCallback: PropTypes.func,
  isValidToSend: PropTypes.func,
};
export default EmailForm;
