import React, { useCallback, useState } from "react";
import { ReportCart } from "compass-commons";
import { DeleteRounded } from "@mui/icons-material";
import { BehaviorSubject } from "rxjs";
import ReportCartService from "../../../services/ReportCartService";
import AlertDialog from "../../commons/dialog/AlertDialog";
import { createReportCart } from "../../../utils/Util";
import SideActions from "./SideActions";
import StateService from "../../../services/StateService";
import { getNavigateBackPath } from "../../../routes/route";

interface DeleteProps {
  cartSubject: BehaviorSubject<ReportCart>;
}

const Delete = ({ cartSubject }: DeleteProps): JSX.Element => {
  const [openDialog, setOpenDialog] = useState(false);
  const stateService: StateService = new StateService();
  const { currentReportCart } = stateService;

  const deleteCart = (rc: ReportCart) => {
    const dataExplorerPath = getNavigateBackPath();

    ReportCartService.deleteCartById(rc?.id)
      .then(() => {})
      .catch((err) => console.error(`Delete cart by id: ${err}`));
    createReportCart()
      .then(() => {
        currentReportCart.next(null);
        window.location.href = dataExplorerPath;
      })
      .catch(() => {
        currentReportCart.next(null);
        window.location.href = dataExplorerPath;
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const dialogCallback = useCallback(
    (response: boolean) => {
      setOpenDialog(response);
      if (response) {
        deleteCart(cartSubject.value);
        setOpenDialog(false);
      }
    },
    [openDialog]
  );

  return (
    <>
      <SideActions
        accessor={<span>Delete</span>}
        accessorPosition="left"
        onClick={handleClickOpen}
        icon={<DeleteRounded color="primary" />}
        className="delete-action-button"
      />
      <AlertDialog
        isDialogOpen={openDialog}
        dialogCallback={dialogCallback}
        title="Delete this report?"
        content="You will not be able to recover it."
        disapprovalText="Cancel"
        approvalText="Yes, delete it"
      />
    </>
  );
};

export default Delete;
