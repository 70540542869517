import React, { useEffect, useState } from "react";
import {
  Incident,
  IncidentStep,
  InfoFieldPhoto,
  InfoFieldTypes,
  ReportCart,
  ReportCartItemTypeEnum,
  useI18n,
} from "compass-commons";
import { BehaviorSubject } from "rxjs";
import { IAlertNotification } from "dms-lib";
import { ReportEmail } from "../../../models/ReportEmail";
import ReportPDFService from "../../../services/ReportPDFService";
import SideButtons from "../../commons/sideButtons/SideButtons";
import Send from "./Send";
import Download from "./Download";
import { ReportGenerateSource } from "../../../models/ReportGenerateSource";
import ReportCartService from "../../../services/ReportCartService";
import StateService from "../../../services/StateService";
import { useStateContext } from "../../../contexts/StateContext";

interface RightSideButtonsProps {
  generatingPdf: BehaviorSubject<ReportGenerateSource>;
  progressPdf: BehaviorSubject<number>;
  alertSubject: BehaviorSubject<IAlertNotification>;
  reportCart: ReportCart;
}

const RightSideButtons = ({
  generatingPdf,
  progressPdf,
  alertSubject,
  reportCart,
}: RightSideButtonsProps): JSX.Element => {
  const { t: translate } = useI18n();
  const [loadingPdf, setLoadingPdf] = useState(generatingPdf.value || false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const reportPdfService = new ReportPDFService(translate);
  const stateService: StateService = useStateContext();
  const { imagesData, incidentDetails } = stateService;
  const [images, setImages] = useState<InfoFieldPhoto[]>();
  const [incidents, setIncidents] = useState<Incident[]>();

  const sendReportViaEmail = (emailDto: ReportEmail) => {
    reportPdfService
      .createPdf("email", false, generatingPdf, progressPdf, reportCart?.name)
      .then((blob) => {
        if (blob) {
          emailDto.reportName = reportCart?.name ? reportCart.name : "Report";
          emailDto.reportCartId = reportCart.id;
          ReportCartService.sendEmail(blob, emailDto, reportCart)
            .then(() => {
              alertSubject.next({
                title: translate("email.sendReport"),
                severity: "success",
              });
            })
            .catch(() => {
              alertSubject.next({
                title: translate("email.failedSendReport"),
              });
            })
            .finally(() => {
              generatingPdf.next(null);
            });
        } else {
          alertSubject.next({
            title: translate("email.failedSendReport"),
          });
          generatingPdf.next(null);
        }
      });
  };

  const isPdfSizeValid = () => {
    if (!reportPdfService.isPdfSizeValid()) {
      alertSubject.next({
        title: translate("pdf.pagesLimitReached"),
      });
      return false;
    }
    return true;
  };

  const createPdf = async () => {
    if (!generatingPdf?.value) {
      if (!isPdfSizeValid()) return;

      try {
        const blob = await reportPdfService.createPdf(
          "download",
          true,
          generatingPdf,
          progressPdf,
          reportCart?.name
        );

        if (blob) {
          ReportCartService.uploadReportPDF(blob, reportCart)
            .then(() => {
              alertSubject.next({
                title: translate("pdf.createReport"),
                severity: "success",
              });
            })
            .catch(() => {
              alertSubject.next({
                title: translate("pdf.failedCreateReport"),
              });
            });
        }
      } catch (error) {
        alertSubject.next({
          title: translate("pdf.failedCreateReport"),
        });
      }
    }
  };

  useEffect(() => {
    const generatingPDFSubscription = generatingPdf.subscribe((val) => {
      setLoadingPdf(val);
    });

    const imagesDataSubscription = imagesData.subscribe(
      (val: InfoFieldPhoto[]) => {
        setImages([...val]);
      }
    );

    const incidentDetailsSubscription = incidentDetails.subscribe(
      (val: Incident[]) => {
        setIncidents([...val]);
      }
    );

    return function cleanup() {
      generatingPDFSubscription.unsubscribe();
      imagesDataSubscription.unsubscribe();
      incidentDetailsSubscription.unsubscribe();
    };
  }, []);

  const getOperatorStepImages = (operatorStep: IncidentStep) => {
    return operatorStep.genericInfoList
      .filter((infoStep) => infoStep.infoFieldType === InfoFieldTypes.PHOTO)
      .map((photoStep) => (photoStep as InfoFieldPhoto).fileId);
  };

  const reportCartContainsDetailsCriteria = (): boolean => {
    return (
      reportCart?.elementList?.findIndex(
        (item) => item.type === ReportCartItemTypeEnum.DETAIL
      ) > -1
    );
  };

  useEffect(() => {
    if (
      reportCart?.elementList?.length > 0 &&
      !reportCartContainsDetailsCriteria()
    ) {
      setIsPageLoading(false);
      return;
    }

    if (!incidents || incidents?.length === 0) {
      setIsPageLoading(true);
      return;
    }

    const incidentsImages = incidents.reduce((imagesArray, incident) => {
      const incidentImages = incident.operatorSteps.reduce(
        (stepsImages, step) => {
          const stepImages = getOperatorStepImages(step);
          if (stepImages.length > 0) {
            stepsImages.push(...stepImages);
          }
          return stepsImages;
        },
        []
      );
      if (incidentImages.length > 0) {
        imagesArray.push(...incidentImages);
      }
      return imagesArray;
    }, []);

    let imageLoading = false;
    for (const image of images) {
      if (!incidentsImages.includes(image.fileId) || !image.mediaFile) {
        imageLoading = true;
        break;
      }
    }

    setIsPageLoading(imageLoading);
  }, [reportCart, images, incidents]);

  return (
    <SideButtons orientation="right">
      <Send
        isPdfSizeValid={isPdfSizeValid}
        sendEmailCallback={sendReportViaEmail}
        disabled={!!loadingPdf || isPageLoading}
        loading={loadingPdf === "email"}
        loadingProgress={progressPdf}
      />
      <Download
        onClick={createPdf}
        disabled={!!loadingPdf || isPageLoading}
        loading={loadingPdf === "download"}
        loadingProgress={progressPdf}
      />
    </SideButtons>
  );
};

export default RightSideButtons;
