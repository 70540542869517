import * as html2pdf from "html2pdf.js";
import { BehaviorSubject } from "rxjs";
import { centeredText } from "../utils/PDFUtils";
import { ReportGenerateSource } from "../models/ReportGenerateSource";
import { getDocument } from "../utils/Util";

const { PDF_PAGES_LIMIT } = appConfig;

const EMAIL: ReportGenerateSource = "email";
export default class ReportPDFService {
  private readonly translate: any;

  readonly COMMON_PAGE_SIZE_PX = 984;

  readonly TABLE_PAGE_SIZE_PX = 1510;

  constructor(translate: any) {
    this.translate = translate;
  }

  startGeneratingPdf = (
    source: NonNullable<ReportGenerateSource>,
    generatingPdfSubject?: BehaviorSubject<ReportGenerateSource>
  ): void => {
    if (generatingPdfSubject) {
      generatingPdfSubject.next(source);
    }
  };

  stopGeneratingPdf = (
    generatingPdfSubject?: BehaviorSubject<ReportGenerateSource>
  ): void => {
    if (generatingPdfSubject) {
      generatingPdfSubject.next(null);
    }
  };

  updateGeneratingPdfProgress = (
    progressPdfSubject: BehaviorSubject<number>,
    progress: number
  ): void => {
    if (progressPdfSubject) {
      progressPdfSubject.next(progress);
    }
  };

  /**
   * Estimates the PDF size (number of pages) based on the size (in pixels) of the incidents in the preview table.
   * @returns number
   */

  getPdfSize = (): number => {
    const incidents = getDocument(document).querySelectorAll(
      ".main-panel-list-item-div"
    );
    let pagesCount = 1; // Cover page
    for (const element of Array.from(incidents)) {
      const isTable = !!element.querySelector("#incidents-table");
      pagesCount += isTable
        ? Math.round(element.clientHeight / this.TABLE_PAGE_SIZE_PX)
        : Math.ceil(element.clientHeight / this.COMMON_PAGE_SIZE_PX);
    }
    return pagesCount;
  };

  /**
   * Validates the pdf size
   * @returns boolean
   */
  isPdfSizeValid = (): boolean => {
    return this.getPdfSize() <= parseInt(PDF_PAGES_LIMIT, 10);
  };

  private readonly getProductImage = (): HTMLImageElement => {
    let img: HTMLImageElement;
    if (isDMS) {
      img = new Image(300, 79);
      img.crossOrigin = "anonymous";
      img.src = `${appConfig.ASSET_STORE}${appConfig.DMS_LOGO_PATH}`;
    } else {
      img = new Image(390, 45);
      img.crossOrigin = "anonymous";
      img.src = `${appConfig.ASSET_STORE}${appConfig.MSI_LOGO_PATH}`;
    }
    return img;
  };

  private readonly getProductLogo = (): HTMLImageElement => {
    let logo: HTMLImageElement;
    if (isDMS) {
      logo = new Image(100, 11);
      logo.crossOrigin = "anonymous";
      logo.src = `${appConfig.ASSET_STORE}${appConfig.AVIGILON_LOGO_PATH}`;
    } else {
      logo = new Image(150, 16);
      logo.crossOrigin = "anonymous";
      logo.src = `${appConfig.ASSET_STORE}${appConfig.MSI_LOGO_PATH}`;
    }
    return logo;
  };

  /**
   * Creates a PDF assuming a structure of: GrandParent -> Parent -> Children (items to add to pdf)
   * */
  createPdf = async (
    source: NonNullable<ReportGenerateSource>,
    saveDoc: boolean,
    generatingPdfSubject?: BehaviorSubject<ReportGenerateSource>,
    progressPdf?: BehaviorSubject<number>,
    reportName = "report"
  ): Promise<Blob> => {
    this.startGeneratingPdf(source, generatingPdfSubject);
    const img = this.getProductImage();
    this.updateGeneratingPdfProgress(progressPdf, 10);
    const logo = this.getProductLogo();
    this.updateGeneratingPdfProgress(progressPdf, 20);
    const totalPages = this.getPdfSize();
    const progressInterval = setInterval(() => {
      const currentProgress = progressPdf.getValue();
      if (currentProgress < 90) {
        this.updateGeneratingPdfProgress(
          progressPdf,
          currentProgress + 90 / (totalPages / 2)
        );
      }
    }, 250);
    const pdfOptions = {
      margin: [24, 24, 48, 24],
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      filename: `${reportName}.pdf`,
      image: { type: "jpeg", quality: 1.0 },
      jsPDF: {
        unit: "px",
        format: "letter",
        orientation: "portrait",
        hotfixes: ["px_scaling"],
      },
    };
    const htmlElement = getDocument(document).querySelector("#main-panel-list");
    const pdf = await html2pdf()
      .set(pdfOptions)
      .from(htmlElement)
      .toPdf()
      .get("pdf");
    clearInterval(progressInterval);
    this.addFrontPage(pdf, img, reportName);
    this.addFooters(pdf, logo);
    if (saveDoc) {
      pdf.save(pdfOptions.filename);
    }
    // Email progress should only finish after sending the email
    if (source !== EMAIL) this.stopGeneratingPdf(generatingPdfSubject);

    return pdf.output("blob");
  };

  // Private methods
  private readonly getProductName = (): string => {
    return isDMS
      ? this.translate("pdf.productName")
      : this.translate("pdf.msiProductName");
  };

  private readonly addFrontPage = (
    doc,
    img: HTMLImageElement,
    reportName: string
  ) => {
    doc.insertPage(1);
    doc.setPage(1);
    doc.addImage(
      img, // imgHTMLElement
      "PNG", // format
      (doc.internal.pageSize.width - img.width) / 2,
      360,
      img.width,
      img.height
    );
    doc.setTextColor("black");
    doc.setFontSize(24);
    centeredText(doc, this.getProductName(), 410 + img.height);
    const initialLineX = (doc.internal.pageSize.width - img.width) / 2;
    const lineY = 410 + img.height + 20;
    doc.line(initialLineX, lineY, initialLineX + img.width, lineY);
    const date = new Date();
    const month = date.toLocaleString("default", { month: "long" });
    doc.setTextColor("#5a5a5a");
    doc.setFontSize(16);
    centeredText(doc, reportName, lineY + 30);
    doc.setFontSize(12);
    centeredText(doc, `${month} ${date.getFullYear()}`, lineY + 60);
  };

  private readonly addFooters = (doc, img: HTMLImageElement) => {
    const pageCount = doc.internal.getNumberOfPages();
    const textPosition = doc.internal.pageSize.height - 24;

    doc.setFontSize(8);
    doc.setTextColor("black");
    for (let i = 2; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(this.getProductName(), 24, textPosition);
      doc.addImage(
        img, // imgHTMLElement
        "PNG", // format
        doc.internal.pageSize.width - img.width - 24,
        isDMS ? textPosition - img.height : textPosition - img.height + 5, // MSI Logo has more 5px then avigilon logo
        img.width,
        img.height
      );
      doc.text(
        this.translate("pdf.pageFooter", {
          pageNumber: i,
          totalPages: pageCount,
        }),
        doc.internal.pageSize.width / 2,
        textPosition,
        {
          align: "center",
        }
      );
    }
  };
}
