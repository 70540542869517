import React from "react";
import { ArrowBackRounded } from "@mui/icons-material";
import "./buttons.module.css";
import SideActions from "./SideActions";
import StateService from "../../../services/StateService";
import { getNavigateBackPath } from "../../../routes/route";

const Back = (): JSX.Element => {
  const stateService: StateService = new StateService();
  const { currentReportCart } = stateService;
  const onClick = () => {
    currentReportCart.next(null);

    window.location.href = getNavigateBackPath();
  };
  return (
    <SideActions
      accessor={<span>Back</span>}
      accessorPosition="left"
      onClick={onClick}
      icon={<ArrowBackRounded color="primary" />}
    />
  );
};

export default Back;
