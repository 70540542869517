import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Dialog } from "dms-lib";
import "./FormDialog.css";

interface FormDialogProps {
  title?: string;
  content?: string | JSX.Element;
  approvalText?: string;
  disapprovalText?: string;
  isDialogOpen: boolean;
  dialogCallback?: (open: boolean) => void;
  contentHeight?: string;
  confirmDisabled?: boolean;
}

const FormDialog = (props: FormDialogProps): JSX.Element => {
  const {
    content,
    title,
    approvalText,
    disapprovalText,
    isDialogOpen,
    dialogCallback,
    contentHeight,
    confirmDisabled,
  } = props;
  const [isOpen, setIsOpen] = useState(isDialogOpen);

  useEffect(() => {
    setIsOpen(isDialogOpen);
  }, [isDialogOpen]);

  const handleClose = () => {
    setIsOpen(false);
    dialogCallback(false);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    dialogCallback(true);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={isOpen}
        dialogTitle={
          <div className="report-form-dialog-title report-form-block">
            {title}
          </div>
        }
        dialogContent={
          <div className="report-form-block">
            {React.isValidElement(content) ? (
              content
            ) : (
              <div style={{ height: contentHeight }}>
                <div>{content}</div>
              </div>
            )}
          </div>
        }
        dialogActions={
          <div className="report-form-block">
            <Button color="primary" variant="text" onClick={handleClose}>
              {disapprovalText}
            </Button>
            <Button
              color="primary"
              variant="text"
              disabled={confirmDisabled}
              onClick={handleConfirm}
            >
              {approvalText}
            </Button>
          </div>
        }
      />
    </div>
  );
};

FormDialog.defaultProps = {
  title: "Confirmation Dialog",
  approvalText: "Yes",
  disapprovalText: "No",
  contentHeight: "12vh",
  dialogCallback: () => null,
  content: null,
  confirmDisabled: false,
};

export default FormDialog;
